<template>
  <div
    style="padding-top: 20px;"
  >
    <p 
      v-for="(item, index) of items"
      :key="index"
    >
    <b> {{$t(`dtouch.dishOrders.formConfirm.${item.name}`, locale)}}: </b> <i>
    {{item.value}}
    </i>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    formData: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    items: []
  }),
  mounted () {
    if (!this.formData) return 
    this.items = JSON.parse(this.formData)
  },
}
</script>

