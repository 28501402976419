<template>
  <div>
    <audio ref="myAudio">
      <source src="/beep.mp3" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
  </div>
</template>
<script>
export default {
  props: {
    play: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    audio: null,
  }),
  watch: {
    play () {
      this.prepareAudio()
    },
  },
  mounted () {
    this.audio = this.$refs['myAudio']
    this.audio.loop = true
    this.prepareAudio()
  },
  methods: {
    prepareAudio () {
      if (this.audio)
        if (this.play) this.audio.play()
        else this.audio.pause()
    }
  }
}
</script>

