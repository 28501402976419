<template>
  <div class="table-main">
    <audio-view :play="play" />
    <v-data-table
      :headers="headers"
      :items="points"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-0"
      @page-count="pageCount = $event"
    
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="ID"
      show-expand
    >
      <template v-slot:item.Status="{ item }">
        {{ $t(`dtouch.dishOrders.statusValues.${item.Status}`, locale) }}
      </template>

      <template v-slot:item.NumItems="{ item }">
        {{item.Items.length}}
      </template>
      <template v-slot:item.Closed="{ item }">
        <v-icon>
          {{ item.Closed ? 'mdi-lock' : 'mdi-lock-open-variant-outline' }}
        </v-icon>
      </template>

      <template v-slot:item.CD="{ item }">
        <span v-if="item.d === 0">
          {{item.sTime}}
          <br>
        </span>
        <v-icon v-if="!item.Checked" style="color:red">mdi-eye</v-icon> {{item.CD}}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <expand-item
          :headers="headers"
          :item="item"
          :locale="locale"
          :onDelete="handleDeleteOrder"
          :onClose="handleCloseOrder"
          :onFinalize="handleFinalizeOrder"
          :note="note"
          :currency="currency"
          :percentage="percentage"
          :allInclusive="allInclusive"
        />
      </template>
    </v-data-table>
    <div class="text-center" v-if="totalPage > 1">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
        @input="handleGetData"
      />
    </div>
  </div>
</template>
<script>
import apiDishOrders from '@/services/apiDishOrders'
import ExpandItem from './ExpandItem'
import AudioView from './Audio'
export default {
  components: {
    ExpandItem,
    AudioView,
  },
  props: {
    forceUpdate: {
      type: Date,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    selectedPoint: {
      type: String,
      default: null,
    },
    selectedStatus: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    //timer: null,
    expanded: [],
    singleExpand: false,
    points: [],
    count: null,
    itemsPerPage: 10,
    totalPage: null,
    page: 1,
    note: null,
    currency: null,
    percentage: null,
    allInclusive: false,
    play: false,
  }),
  computed: {
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    headers() {
      return [
        {
          text: this.$t('dtouch.dishOrders.points.order', this.locale),
          align: 'start',
          sortable: false,
          value: 'ID',
        },
        {
          text: this.$t('dtouch.dishOrders.points.name', this.locale),
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: this.$t('dtouch.dishOrders.numItems', this.locale),
          align: 'center',
          sortable: false,
          value: 'NumItems',
        },
        {
          text: this.$t('dtouch.dishOrders.status', this.locale),
          align: 'start',
          sortable: false,
          value: 'Status',
        },
        /*
        {
          text: this.$t('dtouch.dishOrders.closedOpen', this.locale),
          align: 'center',
          sortable: false,
          value: 'Closed',
        },
        */
        {
          text: this.$t('dtouch.dishOrders.cd', this.locale),
          align: 'end',
          sortable: false,
          value: 'CD',
        },
      ]
    },
    startIndex () {
      return (this.page -1) * this.itemsPerPage
    },
  },
  watch: {
    forceUpdate () {
      this.page = 1
      this.handleGetData()
    },
    selectedPoint () {
      this.page = 1
      this.handleGetData()
    },
    selectedStatus () {
      this.page = 1
      this.handleGetData()
    },
    expanded (v) {
      if (!v || v.length < 1) return 
      const aux = v[0]
      if (!aux.Checked && aux.ID) 
        apiDishOrders.checkOrder(aux.ID)
          .then(() => {
            this.handleGetData()
          })                          
    },
  },
  mounted () {
    /*this.timer = setInterval(() => {
      this.handleGetData()
    }, 60000)*/
    this.handleGetConfig()
    this.handleGetData()
  },
  /*beforeDestroy() {
    clearInterval(this.timer)
  },*/
  methods: {
    handleGetConfig () {
      apiDishOrders.getConfig ()
        .then(response => {
          this.currency = response && response.Currency ? response.Currency : '€'
          this.percentage = response && response.Percentage ? response.Percentage : null
          this.note = response && response.Note ? JSON.parse(response.Note) : null
          this.allInclusive = response && response.AllInclusive ? true : false
        })
    },
    handleGetData () {
      apiDishOrders.getAllOrdersFiltered(this.startIndex, this.itemsPerPage, this.selectedPoint ? this.selectedPoint : null, this.selectedStatus) 
        .then((response) => {
          /*this.points = response.data.data.map(item => {
            item.CD = new Intl.DateTimeFormat(this.locale, { dateStyle: 'full', timeStyle: 'medium' }).format(new Date(item.CD))
            return item
          })*/
          this.points = response.data.data.map(item => {
            item.CD = new Intl.DateTimeFormat(this.locale, { dateStyle: 'full', timeStyle: 'medium' }).format(new Date(item.CD))
            return item
          })
          this.play = this.points.filter(x => !x.Checked && x.Status === 'confirmed').length > 0 ? true : false
          this.count = response.data.count
          this.totalPage = Math.ceil(this.count / this.itemsPerPage)
        })
    },
    handleDeleteOrder (v) {
      apiDishOrders.deleteOrder(v, 'private') 
        .then(() => {
          this.handleGetData()
        })
    },
    handleCloseOrder (v) {
      apiDishOrders.closeOrder(v) 
        .then(() => {
          this.handleGetData()
        })
    },
    handleFinalizeOrder (v) {
      apiDishOrders.finalizeOrder(v) 
        .then(() => {
          this.handleGetData()
        })
    },
  },
}
</script>
<style scoped>
.table-main {
  background-color: white;
  padding-bottom: 10px;
}
.table-main .v-data-table {
  border-radius: 0 !important;
}
</style>

