var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-main"},[_c('audio-view',{attrs:{"play":_vm.play}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.points,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"ID","show-expand":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("dtouch.dishOrders.statusValues." + (item.Status)), _vm.locale))+" ")]}},{key:"item.NumItems",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Items.length)+" ")]}},{key:"item.Closed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.Closed ? 'mdi-lock' : 'mdi-lock-open-variant-outline')+" ")])]}},{key:"item.CD",fn:function(ref){
var item = ref.item;
return [(item.d === 0)?_c('span',[_vm._v(" "+_vm._s(item.sTime)+" "),_c('br')]):_vm._e(),(!item.Checked)?_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v("mdi-eye")]):_vm._e(),_vm._v(" "+_vm._s(item.CD)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('expand-item',{attrs:{"headers":headers,"item":item,"locale":_vm.locale,"onDelete":_vm.handleDeleteOrder,"onClose":_vm.handleCloseOrder,"onFinalize":_vm.handleFinalizeOrder,"note":_vm.note,"currency":_vm.currency,"percentage":_vm.percentage,"allInclusive":_vm.allInclusive}})]}}])}),(_vm.totalPage > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"total-visible":5},on:{"input":_vm.handleGetData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }