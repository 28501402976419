<template>
  <td :colspan="headers.length"
    style="background-color: #CCCCCC80;"
  >
    <v-layout wrap>
      <v-flex 
        v-bind="{[`xs${isMobile ? 6 : 5}`]: true}"
        style="padding: 10px"
      >
        <v-layout
          wrap
          v-for="(orderItem, index) of item.Items"
          :key="index"
          style="max-width: 600px;padding: 10px;"
        >
          <v-flex xs8>
            <b>
              {{ orderItem.ExtraData ? ' ↳ ' : '' }}{{orderItem.Name}}
            </b>
          </v-flex>
          <v-flex xs4 style="text-align: right;">
            <span>
              {{ orderItem.Price.toFixed(2) }}{{currency}}
            </span>
            <span v-if="!percentage && orderItem.Price2 !== null">
              <br/>*{{ orderItem.Price2.toFixed(2) }}{{currency}}
            </span>
            <span v-if="percentage">
              <br/>*{{ orderItem.PriceDiscount.toFixed(2) }}{{currency}}
            </span>
          </v-flex>
          <v-flex xs12 style="padding-top: 10px; opacity: 0.6;">
            <v-divider />
          </v-flex>
        </v-layout>
        <!--v-divider/-->
        <v-layout 
          wrap
          style="max-width: 600px;padding: 10px;"
        >
          <v-flex xs4>
            <h2><b>Total</b></h2>
          </v-flex>
          <v-flex xs8 style="text-align: right;">
            <template v-if="!percentage">
              <h2><b>{{ sTotal }}</b></h2>
              <h3 v-if="note" style="font-size: 0.6rem; opacity: 0.5;"><b>{{ getTranslation(note, locale) }}</b></h3>
              <h2><b>{{sTotal2}}</b></h2>
            </template>
            <template v-else>
              <h2><b>{{ sTotal }}</b></h2>
              <h3 v-if="note" style="font-size: 0.6rem; opacity: 0.5;"><b>{{ getTranslation(note, locale) }}</b></h3>
              <h2><b>{{ sTotalPercentage }}</b></h2>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex 
        v-bind="{[`xs${isMobile ? 6 : 4}`]: true}"
      >
        <form-data 
          v-if="item.FormData"
          :formData="item.FormData"
          :locale="locale"
        />
      </v-flex>
      <v-flex 
        v-bind="{[`xs${isMobile ? 12 : 3}`]: true}"
        style="padding: 10px"
      >
        <v-btn
          v-if="onDelete"
          block
          dark
          color="red"
          @click="onDelete(item.ID)"
          style="margin-bottom: 10px"
        >
          {{ $t('common.delete', locale) }}
        </v-btn>
        <v-btn
          v-if="!item.Closed && onClose"
          block
          dark
          color="secondary"
          @click="onClose(item.ID)"
          style="margin-bottom: 10px"
        >
          {{ $t('common.close', locale) }}
        </v-btn>
        <v-btn
          v-if="item.Status === 'confirmed' && onFinalize"
          block
          dark
          color="primary"
          @click="onFinalize(item.ID)"
          style="margin-bottom: 10px"
        >
          {{ $t('dtouch.dishOrders.finalize', locale) }}
        </v-btn>
      </v-flex>
    </v-layout>
  </td>
</template>
<script>
import apiDishOrders from '@/services/apiDishOrders'
import utils from '@/services/utils'
import FormData from './FormData'
export default {
  components: {
    FormData,
  },
  props: {
    headers: {
      type: Array,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onDelete: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    onFinalize: {
      type: Function,
      default: null,
    },
    note: {
      type: Object,
      default: null
    },
    currency: {
      type: String,
      default: '€',
    },
    percentage: {
      type: Number,
      default: null,
    },
    allInclusive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    orderItems: null,
  }),
  computed: {
    total () {
      return this.item.Items.reduce((accumulator, o) => {
        return accumulator + o.Price
      }, 0)
    },
    totalPercentage () {
      return this.item.Items.reduce((accumulator, o) => {
        return accumulator + o.PriceDiscount
      }, 0)
    },

    total2 () {
      if (this.item.Items.filter(x => x.Price2 !== null).length === 0) return null

      return !this.item ? 0 : this.item.Items.reduce((accumulator, o) => {
        return accumulator + (o.Price2 !== null ? o.Price2 : o.Price)
      }, 0)
    },
    sTotal () {
      return this.total !== null ? `${this.total.toFixed(2)}${this.currency}` : null
    },
    sTotal2 () {
      return this.total2 !== null ? `*${this.total2.toFixed(2)}${this.currency}` : null
    },
     sTotalPercentage () {
      return this.totalPercentage !== null ? `*${this.totalPercentage.toFixed(2)}${this.currency}` : null
    },
    isMobile () {
      return utils.isMobile()
    },
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData () {
      apiDishOrders.getAllOrderItemByOrderID(this.item.ID) 
        .then((response) => {
          this.orderItems = response.data
        })
    },
  },
}
</script>

